import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { fadeInOutAnimation } from '@frr/shared/animations';
import { SubscriptionsService } from '@swagger/services/subscriptions.service';
import { of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { ROUTE_TOKENS } from '@frr/shared/app-config';

@Component({
  animations: [fadeInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, RouterLink],
  selector: 'frr-footer',
  standalone: true,
  styleUrl: './footer.component.scss',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  readonly ROUTE_TOKENS = ROUTE_TOKENS;
  readonly showSuccessMessage$$ = signal<boolean>(false);

  readonly subscribeForm = this.formBuilder.group({
    agreementCheckbox: [false, Validators.requiredTrue],
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private subscriptionService: SubscriptionsService,
    private destroyRef: DestroyRef,
  ) {}

  private toggleSuccessMessage(): void {
    this.showSuccessMessage$$.set(!this.showSuccessMessage$$());
  }

  submit(): void {
    this.subscribeForm.markAllAsTouched();

    if (!this.subscribeForm.valid) return;

    const email = <string>this.subscribeForm.get('email')?.value;

    this.subscriptionService
      .subscriptionsSubscribeCreate({ email, frr: true })
      .pipe(
        tap(() => this.toggleSuccessMessage()),
        switchMap(() => of(null).pipe(delay(5_000))),
        tap(() => this.toggleSuccessMessage()),
        catchError((error: unknown) => {
          // eslint-disable-next-line no-console
          console.error(error);
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
