<div class="header" [class.header-transparent]="!scrolled"  [class.header-blueText]="pagePath == [ROUTE_TOKENS.ABOUT]">
  <div class="container header__container">
    <a class="header__logo" [routerLink]="['/']">
      <img src="./assets/images/logoFrr.svg" alt="" />
    </a>
    <div class="header__menu" [class.header__menu-show]="showMenu">
      <div class="header__itemContainer">
        <button
          type="button"
          class="header__item"
          [class.header__item-active]="showSubMenu === 1"
          (mouseenter)="showSubMenu = 0; showSubMenu = 1"
          (mouseleave)="showSubMenu = 0"
        >
          О Форуме
        </button>
        <div
          class="header__menu-popup"
          [class.header__menu-popupShow]="showSubMenu === 1"
          (mouseenter)="showSubMenu = 1"
          (mouseleave)="showSubMenu = 0"
        >
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.ABOUT]"
            (click)="toggleMenu()"
          >
            О Форуме
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.ORGANIZING_COMMITTEE]"
            (click)="toggleMenu()"
          >
            ОРГКОМИТЕТ ФОРУМА
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.PARTNERS]"
            (click)="toggleMenu()"
          >
            Партнеры
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.EXPERT_BOARD]"
            (click)="toggleMenu()"
          >
            Экспертный совет
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.LOCATION]"
            (click)="toggleMenu()"
          >
            Место проведения
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.CONTACTS]"
            (click)="toggleMenu()"
          >
            Контакты
          </a>
        </div>
      </div>
      <div class="header__itemContainer">
        <button
          type="button"
          class="header__item"
          [class.header__item-active]="showSubMenu === 2"
          (mouseenter)="showSubMenu = 2"
          (mouseleave)="showSubMenu = 0"
        >
          Программа
        </button>
        <div
          class="header__menu-popup"
          [class.header__menu-popupShow]="showSubMenu === 2"
          (mouseenter)="showSubMenu = 2"
          (mouseleave)="showSubMenu = 0"
        >
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.PROGRAM]"
            (click)="toggleMenu()"
          >
            Деловая программа
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.SPEAKERS]"
            (click)="toggleMenu()"
          >
            Спикеры
          </a>
        </div>
      </div>
      <div class="header__itemContainer">
        <button
          type="button"
          class="header__item"
          [class.header__item-active]="showSubMenu === 3"
          (mouseenter)="showSubMenu = 3"
          (mouseleave)="showSubMenu = 0"
        >
          Принять участие
        </button>
        <div
          class="header__menu-popup"
          [class.header__menu-popupShow]="showSubMenu === 3"
          (mouseenter)="showSubMenu = 3"
          (mouseleave)="showSubMenu = 0"
        >
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.PARTICIPATION]"
            (click)="toggleMenu()"
          >
            Как посетитель
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.PARTICIPATION_FOR_EXPONENT]"
            (click)="toggleMenu()"
          >
            Как экспонент
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.PARTICIPATION_FOR_SPEAKER]"
            (click)="toggleMenu()"
          >
            Как спикер
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.PARTICIPATION_TERMS]"
            (click)="toggleMenu()"
          >
            Условия участия
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.ACCOMMODATION]"
            (click)="toggleMenu()"
          >
            Проживание
          </a>
        </div>
      </div>
      <div class="header__itemContainer">
        <button
          type="button"
          class="header__item"
          [class.header__item-active]="showSubMenu === 4"
          (mouseenter)="showSubMenu = 4"
          (mouseleave)="showSubMenu = 0"
        >
          ПРЕСС-ЦЕНТР
        </button>
        <div
          class="header__menu-popup"
          [class.header__menu-popupShow]="showSubMenu === 4"
          (mouseenter)="showSubMenu = 4"
          (mouseleave)="showSubMenu = 0"
        >
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.NEWS]"
            (click)="toggleMenu()"
          >
            нОВОСТИ
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.MATERIALS]"
            (click)="toggleMenu()"
          >
            Материалы Форума
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.CONTACTS]"
            (click)="toggleMenu()"
          >
            Контакты
          </a>
        </div>
      </div>
      <div class="header__itemContainer">
        <button
          type="button"
          class="header__item"
          [class.header__item-active]="showSubMenu === 5"
          (mouseenter)="showSubMenu = 5"
          (mouseleave)="showSubMenu = 0"
        >
          ИСТОРИЯ ФОРУМА
        </button>
        <div
          class="header__menu-popup"
          [class.header__menu-popupShow]="showSubMenu === 5"
          (mouseenter)="showSubMenu = 5"
          (mouseleave)="showSubMenu = 0"
        >
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.HISTORY2023]"
            (click)="toggleMenu()"
          >
            ИТОГИ ФРР-2023
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.HISTORY2022]"
            (click)="toggleMenu()"
          >
           ИТОГИ ФРР-2022
          </a>
          <a
            class="header__item"
            [routerLink]="[ROUTE_TOKENS.HISTORY2021]"
            (click)="toggleMenu()"
          >
           ИТОГИ ФРР-2021
          </a>
        </div>
      </div>
      <div class="header__socials" style="display: none">
        <a href="#" class="header__social">
          <img src="./assets/images/logoRutube.svg" alt="" />
        </a>
        <a href="#" class="header__social">
          <img src="./assets/images/logoTelegram.svg" alt="" />
        </a>
      </div>
    </div>
    <a 
      href="#"
      [routerLink]="[ROUTE_TOKENS.CONTACTS]" 
      class="header__button">
      Контакты
    </a>
    <button
      type="button"
      class="header__burgerButton mobileBlock"
      [class.header__burgerButton-show]="showMenu"
      (click)="toggleMenu()"
    ></button>
  </div>
</div>
