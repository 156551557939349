import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@frr/env';
import { LocalStorageService } from '@frr/shared/api';
import { Observable } from 'rxjs';

export function HttpHeadersInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const authToken = inject(LocalStorageService).getItem(
    environment.TOKEN_STORAGE_KEY,
  );

  if (authToken) {
    const authRequest = request.clone({
      headers: request.headers.set('visitor', authToken),
    });
    return next(authRequest);
  }
  return next(request);
}
