import {
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipeConfig,
  registerLocaleData,
} from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {
  ApplicationConfig,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { HttpHeadersInterceptor } from '@frr/core';
import { environment } from '@frr/env';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { register as registerSwiper } from 'swiper/element';

import { appRoutes } from './app.routes';
import { ApiConfiguration } from './swagger/api-configuration';

registerLocaleData(localeRu);
dayjs.locale('ru');
dayjs.extend(utc);
registerSwiper();

const DEFAULT_LOCALE = 'ru-RU';
const DEFAULT_TIMEZONE = 'UTC+3';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true, runCoalescing: true }),
    provideAnimationsAsync(),
    provideRouter(
      appRoutes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'disabled' }),
    ),
    provideHttpClient(withInterceptors([HttpHeadersInterceptor])),
    provideEnvironmentNgxMask(),
    provideAngularSvgIcon(),
    {
      provide: ApiConfiguration,
      useValue: { rootUrl: `/api/v${environment.API_VERSION}` },
    },
    { provide: LOCALE_ID, useValue: DEFAULT_LOCALE },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: <DatePipeConfig>{ timezone: DEFAULT_TIMEZONE },
    },
  ],
};
