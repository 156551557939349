import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { RouterLink, Router, NavigationEnd } from '@angular/router';
import { ROUTE_TOKENS } from '@frr/shared/app-config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink],
  selector: 'frr-header',
  standalone: true,
  styleUrl: './header.component.scss',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  readonly ROUTE_TOKENS = ROUTE_TOKENS;
  public scrolled: boolean | undefined;
  public showMenu: boolean | undefined;
  public showSubMenu: number | undefined;
  pagePath: any;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.pagePath = router.url.replace('/', '');
      }
  });
  }

  @HostListener('window:scroll')
  onWindowScroll(): void {
    this.scrolled = window.scrollY > 70;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
    // disableBodyScroll(this.targetElement);
  }

}
