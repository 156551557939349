<div class="footer">
  <div class="footer__subscribeBlock">
    <p class="footer__text">
      Подпишитесь на рассылку и получайте только полезные сообщения
    </p>
    <form
      class="footer__form"
      [formGroup]="subscribeForm"
      (ngSubmit)="submit()"
    >
      <input
        formControlName="email"
        type="email"
        class="footer__input"
        id="footerInputMail"
        placeholder="Ваш Email"
        name="email"
        autocomplete="email"
        required
      />
      <button type="submit" class="footer__button button button-pink"></button>
      <input
        formControlName="agreementCheckbox"
        type="checkbox"
        class="footer__consent"
        id="footerConsent"
        required
      />
      <label for="footerConsent" class="footer__label">
        Даю согласие на обработку персональных данных
      </label>
      @if (showSuccessMessage$$()) {
        <div class="footer__done" @fadeInOut>ФОРМА УСПЕШНО ОТПРАВЛЕНА</div>
      }
    </form>
  </div>
  <div class="footer__linksBlock">
    <div class="footer__logoBlock">
      <img src="./assets/images/logoFrr.svg" alt="" class="footer__logo" />
      <p class="footer__address">
        <a href="https://www.pir.ru/#2024">Организатор ООО «ПИР Экспо»</a> <br />
        Москва, 3-я ул. Ямского поля, <br />
        владение 2, к. 26, 2-й этаж, офис 117 <br />
      </p>
    </div>
    <div class="footer__links">
      <div class="footer__menu">
        <a [routerLink]="[ROUTE_TOKENS.ABOUT]" class="footer__item"> О Форуме </a>
        <a [routerLink]="[ROUTE_TOKENS.PROGRAM]" class="footer__item"> ПРОГРАММА </a>
        <a [routerLink]="[ROUTE_TOKENS.PARTICIPATION]" class="footer__item"> Принять участие </a>
        <a [routerLink]="[ROUTE_TOKENS.NEWS]" class="footer__item"> ПРЕСС-ЦЕНТР </a>
        <a [routerLink]="[ROUTE_TOKENS.CONTACTS]" class="footer__item"> КОНТАКТЫ </a>
      </div>
      <div class="footer__docs">
        <a href="/assets/files/oferta.pdf" class="footer__doc">Публичная оферта</a>
        <a href="/assets/files/personal_data.pdf" class="footer__doc"
          >Соглашение об обработке персональных данных</a
        >
        <div class="footer__socials" style="display: none;">
          <a href="#" class="footer__social">
            <img src="./assets/images/logoRutube.svg" alt="" />
          </a>
          <a href="#" class="footer__social">
            <img src="./assets/images/logoTelegram.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__rules">
    <p>
      Использование материалов, размещенных на сайте, допускается только с письменного разрешения ООО "ПИР ЭКСПО" или соответствующего правообладателя. Запрещается автоматизированное извлечение размещенной информации любыми сервисами без официального разрешения ООО "ПИР ЭКСПО". 
    </p>
  </div>
</div>
