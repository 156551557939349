import { ViewportScroller } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { handleScrollOnNavigation, PersistStorageService } from '@frr/core';
import { environment } from '@frr/env';
import { FooterComponent } from '@frr/layout/footer';
import { HeaderComponent } from '@frr/layout/header';
import { LocalStorageService } from '@frr/shared/api';
import { GetVisitorTokenService } from '@swagger/services/get-visitor-token.service';
import { catchError, throwError } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, HeaderComponent, FooterComponent],
  selector: 'frr-root',
  standalone: true,
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private tokenService: GetVisitorTokenService,
    private localStorageService: LocalStorageService,
    private persistStorageService: PersistStorageService,
    private viewportScroller: ViewportScroller,
    private destroyRef: DestroyRef,
  ) {
    this.persistStorageService.setPersistStorage();
    this.viewportScroller.setHistoryScrollRestoration('manual');
    handleScrollOnNavigation();
  }

  private initApp(): void {
    if (this.localStorageService.getItem(environment.TOKEN_STORAGE_KEY)) return;

    this.tokenService
      .getVisitorTokenList({ shopId: environment.SHOP_ID })
      .pipe(
        catchError(() =>
          throwError(() => new Error('Error while getting visitor token')),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        // eslint-disable-next-line no-console
        error: (error: unknown) => console.error((<Error>error)?.message),
        next: (response) =>
          this.localStorageService.setItem(
            environment.TOKEN_STORAGE_KEY,
            response.key,
          ),
      });
  }

  ngOnInit(): void {
    this.initApp();
  }
}
